import React from 'react'

const DevPage = () => {
    const token = sessionStorage.getItem('accessToken');
    const handleCopyToken = () => {
        navigator.clipboard.writeText(token);
    }
  return (
    <div className='container'>
        <h1>Dev Page</h1>
        <h3>Token: <h5 className='text-danger'>{token}</h5></h3>
        <button className='btn btn-primary' onClick={handleCopyToken}>Copy Token</button>
    </div>
  )
}

export default DevPage