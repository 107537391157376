import 'katex/dist/katex.min.css';
import Latex from 'react-latex-next';
import "./MathRender.css"

const MathRender = (props) => {
    return (
        <div style={{ userSelect: 'text' }}>
            <Latex 
                className={props.className}
                delimiters={[
                    { left: '$$', right: '$$', display: true },
                    { left: '\\(', right: '\\)', display: false},
                    { left: '\\[', right: '\\]', display: true},
                ]}
                strict
            >
                {props.text}
            </Latex>
            {/* Hidden text for copying */}
            <div 
                style={{ 
                    position: 'absolute', 
                    left: '-9999px',
                    top: 0,
                }} 
                aria-hidden="true"
            >
                {props.text}
            </div>
        </div>
    );
}

export default MathRender;