import { useState, useEffect } from 'react';

const useSessionTokenSharing = () => {
  // Khởi tạo state token từ sessionStorage (nếu có)
  const [token, setToken] = useState(() => sessionStorage.getItem('accessToken') || null);

  useEffect(() => {
    const tokenChannel = new BroadcastChannel('accessTokenChannel');
    
    // Nếu tab hiện tại có token, gửi ngay khi mount
    const existingToken = sessionStorage.getItem('accessToken');
    if (existingToken) {
      tokenChannel.postMessage({ type: 'SEND_TOKEN', token: existingToken });
    }

    // Hàm gửi token nếu có
    const sendToken = () => {
      const currentToken = sessionStorage.getItem('accessToken');
      if (currentToken) {
        tokenChannel.postMessage({ type: 'SEND_TOKEN', token: currentToken });
      }
    };

    // Lắng nghe thông điệp từ các tab khác
    tokenChannel.onmessage = (event) => {
      const { type, token: receivedToken } = event.data;
      if (type === 'REQUEST_TOKEN') {
        sendToken();
      } else if (type === 'SEND_TOKEN' && receivedToken) {
        // Nếu tab này chưa có token, lưu token nhận được và cập nhật state
        if (!sessionStorage.getItem('accessToken')) {
          sessionStorage.setItem('accessToken', receivedToken);
          setToken(receivedToken);
          console.log('Token received from another tab:', receivedToken);
        }
      }
    };

    // Nếu tab hiện tại chưa có token, đợi một chút rồi gửi yêu cầu token
    if (!sessionStorage.getItem('accessToken')) {
      setTimeout(() => {
        tokenChannel.postMessage({ type: 'REQUEST_TOKEN' });
      }, 100);
    }

    return () => {
      tokenChannel.close();
    };
  }, []);

  return token;
};

export default useSessionTokenSharing;
