import './Mock.css'
import '../../Assets/CSS/style.css'
import { useDispatch, useSelector } from 'react-redux';
import Choice from '../../components/Choice';
import { setCurrentQuestion, fetchMock, setChoice, setMarkedQuestion, setModule, resetMock } from '../../redux/slice/mockSlice';
import { useState, useEffect } from 'react';
import QuestionsMenu from '../../components/QuestionMenu/QuestionsMenu';
import Timer from '../../components/Timer/Timer';
import { useNavigate, useParams } from 'react-router-dom';
import 'katex/dist/katex.min.css';
import MathRule from '../../components/Math/MathRule';
import MathReview from '../../components/Math/MathReview';
import Loading from '../../components/Loading/Loading';
import Preview from '../../components/PopupNextSection/Preview';
import { calculateTotalScore } from '../../utils/calculateTotalScore';
import { setTime } from '../../redux/slice/timerSlice';
import { updateAttemptAPI } from '../../apis';
import DesmosComponent from '../../components/DesmosComponent/DesmosComponent';
import MathRender from '../../components/Math/MathRender';
import Highlight from '../../components/Highlight/Hightlight';
import { message } from 'antd';

function Mock() {

    const dispatch = useDispatch();
    const [isPreview, setIsPreview] = useState(false);
    const [isDesmosOpen, setIsDesmosOpen] = useState(false); // State to control Desmos popup
    const mock = useSelector((state) => state.mock);
    const isLoading = useSelector(state => state.mock.isLoading);
    const user = useSelector(state => state.user);
    const navigate = useNavigate();
    const [isHighlightEnable, setIsHighlightEnable] = useState(false);
    const time = useSelector(state => state.timer.time);
    const { attemptId } = useParams(); // Lấy attemptId từ URL
    const [isOpenMenu, setOpenMenu] = useState(false)
    const currentQuestion = useSelector((state) => state.mock.currentQuestion);
    const isMarked = useSelector(state => state.mock.markedQuestions[currentQuestion.id]);
    const inputValue = useSelector(state => state.mock.choices[currentQuestion.id]);
    const handleMarkButton = () => {
        dispatch(setMarkedQuestion(currentQuestion.id));
    }
    const handleOpenMenu = () => {
        setOpenMenu(!isOpenMenu);
    }
    const handleBackButton = () => {
        if (currentQuestion.id === 1) {
            setIsPreview(false);
        } else if (isPreview) {
            setIsPreview(false);
        } else if (currentQuestion.id === mock.startAtQuestions + 1) {
            return;
        } else {
            dispatch(setCurrentQuestion(currentQuestion.id - 1));
        }
    }

    const handleTimeOut = () => {
        dispatch(setModule(mock.module + 1));
        switch (mock.module) {
            case 1:
                dispatch(setTime(32 * 60));
                break;
            case 2:
                dispatch(setTime(32 * 60));
                break;
            case 3:
                dispatch(setTime(35 * 60));
                break;
            case 4:
                dispatch(setTime(35 * 60));
                break;
            default:
                dispatch(setTime(32 * 60));
                break;
        }
        // dispatch(setCurrentQuestion(currentQuestion.id + 1));
    }
    const handleNextButton = () => {
        if (isPreview) {
            dispatch(setModule(mock.module + 1));
            switch (mock.module) {
                case 1:
                    dispatch(setTime(32 * 60));
                    break;
                case 2:
                    dispatch(setTime(32 * 60));
                    break;
                case 3:
                    dispatch(setTime(35 * 60));
                    break;
                case 4:
                    dispatch(setTime(35 * 60));
                    break;
                default:
                    dispatch(setTime(32 * 60));
                    break;
            }
            // dispatch(setCurrentQuestion(currentQuestion.id + 1));
            setIsPreview(false);
        } else if (currentQuestion.id === mock.endAtQuestions) {
            setIsPreview(true)
        } else {
            dispatch(setCurrentQuestion(currentQuestion.id + 1));
        }
    }



    const handleChangeInput = (event) => {
        dispatch(setChoice({ id: currentQuestion.id, choice: event.target.value }));
    }
    // Fetch data bài test từ server
    useEffect(() => {
        dispatch(fetchMock(attemptId))
    }, [attemptId, dispatch]);
    // đá người dùng ra ngoài nếu attempt đã hoàn thành
    useEffect(() => {

        if (mock.attemptId === attemptId && mock.status === "FINISH") {
            navigate(`/score?attemptId=${attemptId}`);
        }

    }, [mock.status, attemptId, navigate, mock.attemptId])

    // Update attempt data to server every 10 seconds
    useEffect(() => {
        if (time === 0) {
            if (mock.module !== 4) {
                handleTimeOut();
            } else {
                handleSubmit();
            }

        }
        if (time % 10 !== 0 || time === 32 * 60) return;
        try {
            const updateAttempt = async () => {
                const updateAttempt = await updateAttemptAPI(attemptId, mock, time); // Call the reusable function
                console.log(updateAttempt);
            }
            updateAttempt();
        } catch (error) {
           message.error('Auto save failed. Please logout and login again or contact to admin');
        }
        //eslint-disable-next-line
    }, [time]);
    // Submit bài test
    const handleSubmit = async () => {
        const totalScore = await calculateTotalScore(mock.data, mock.choices);
        await updateAttemptAPI(attemptId, mock, time, "FINISH", totalScore, new Date());

        if (mock.type === "PRACTICE_TEST") {
            navigate(`/score?attemptId=${attemptId}`);
        } else {
            dispatch(resetMock());
            navigate('/test-completion');
        }

    }

    // Function to toggle Desmos visibility
    const toggleDesmos = () => {
        setIsDesmosOpen(!isDesmosOpen);
    };
    if (isLoading) {
        return <Loading />
    }

    return (
        <div className="mock-page">
            <DesmosComponent visible={isDesmosOpen} setVisible={setIsDesmosOpen} />
            <div className="text-center">
                <div className="mx-auto cold-md-6 py-2 main-card-test" style={{ position: 'relative' }}>
                    {/* Top Section */}
                    {!isPreview && <section className='mx- col-md-12  d-flex justify-content-between main-header-test '>
                        <div className="text-left" style={{ width: '200px' }}>
                            <p className="m-0 medium font-20">Section {mock.module === 1 || mock.module === 2 ? 1 : 2}, Module {mock.module === 1 || mock.module === 3 ? 1 : 2}: {mock.module < 3 ? 'Reading and Writing' : ' Math'}</p>
                            <button className="btn px-0">
                                <strong>
                                    Directions &nbsp;
                                    <i className="bi bi-chevron-down"></i>
                                </strong>

                            </button>
                        </div>
                        {/* Timer */}
                        <div className="align-items-center ">

                            <Timer />
                        </div>
                        <div className="d-flex view-more">
                            {currentQuestion.skill === "RW" && <button className='d-inline btn' onClick={() => setIsHighlightEnable(!isHighlightEnable)}>
                                <i className="bi bi-highlighter"></i>
                                <br />
                                <p className='d-none d-md-block'>Highlight & Note</p>
                                <hr className={`${isHighlightEnable ? "highlight-hr-on" : "highlight-hr-off"}`} />
                            </button>}
                            {currentQuestion.skill !== "RW" && <button className='medium d-inline btn' onClick={toggleDesmos}>
                                <i className="bi bi-calculator-fill"></i>
                                <br/>
                                Calculator
                            </button>}
                            <button className='medium d-inline btn' >
                                <i className="bi bi-three-dots-vertical"></i>
                                <br />
                                More
                            </button>
                        </div>
                    </section>}
                    <hr style={{ border: '1px solid #000', margin: '0 0 15px 0' }} />
                    {/* Middle Section  */}
                    {isPreview ?
                        <Preview setIsPreview={setIsPreview} /> :
                        <section className="mid-section col-md-12 row text-center overflow-auto">

                            {/* Left Container */}

                            {currentQuestion.skill === 'MI' ?
                                <MathRule />
                                :
                                (currentQuestion.skill !== "MA" ?
                                    <div className="overflow-auto  col-sm-12 col-md-6 row-with-divider p-4 text-start left-divider" >
                                        <div>
                                            {/* <Highlight text={currentQuestion.passage} /> */}

                                            <Highlight className="question-body" isHighlightEnable={isHighlightEnable} questionId={currentQuestion.id} text={currentQuestion.passage} />
                                        </div>
                                    </div> : null)}
                            {/* Right Container */}

                            <div className={`overflow-auto  col-sm-12  
                             ${currentQuestion.skill === "MA" ? "col-md-7 m-auto" : "col-md-6 row-with-divider"} 
                                row-with-divider p-4`}>
                                <div className="d-flex title-container">
                                    <p className="question-number">{mock.module === 1 ? currentQuestion.id : currentQuestion.id - mock.startAtQuestions}</p>
                                    <button className="btn d-flex align-items-center mark-btn" onClick={() => handleMarkButton()}>
                                        <span>
                                            {!isMarked ? <i className="bi bi-bookmark"></i>
                                                :
                                                <i className="bi bi-bookmark-fill text-danger"></i>
                                            }
                                            &nbsp;Mark for Review
                                        </span>
                                    </button>
                                </div>
                                {currentQuestion.skill === "RW" &&
                                    <Highlight className="question-body" isHighlightEnable={isHighlightEnable} isLeft={false} questionId={currentQuestion.id} text={currentQuestion.question} />
                                }
                                {currentQuestion.skill === "MA" && <p className="my-4 text-start question-body" id="question" >
                                    <MathRender text={currentQuestion.question} />
                                </p>}
                                {/* Choices Container */}
                                <div className="choices-container">

                                    {currentQuestion.skill === "MI" ?
                                        <div>
                                            <p className="my-4 text-start question-body" id="question" >
                                                <MathRender text={currentQuestion.question} />
                                            </p>
                                            <input type="text" className="form-control choice-input" placeholder="Your answer" value={inputValue} onChange={handleChangeInput} />
                                            <h5 className="text-start mt-2">Answer Preview: &nbsp; <MathReview input={inputValue} /> </h5>

                                        </div>
                                        :
                                        currentQuestion.choices.map((choice, index) =>
                                            <Choice key={`${choice.key}-${index}`} customKey={choice.key} text={choice.text} />
                                        )}

                                </div>
                            </div>
                        </section>}

                </div>
                {/* Bottom Section */}
                <section className="botton-section container-fluid ">
                    <div className="col-md-2 d-flex align-items-center ">
                        <h5>{user.name}</h5>
                    </div>
                    <div className="col-md-8 question-menu-container">
                        {isOpenMenu && <QuestionsMenu handleOpenMenu={handleOpenMenu} />}
                        {!isPreview &&
                            <button className="btn btn-dark" onClick={() => handleOpenMenu()}>
                                Question {mock.module === 1 ? currentQuestion.id : currentQuestion.id - mock.startAtQuestions} of {mock.endAtQuestions - mock.startAtQuestions} &nbsp;
                                <i className="bi bi-chevron-up"></i>
                            </button>}

                    </div>
                    <div className="col-md-2">
                        <button onClick={() => handleBackButton()} className="btn btn-dark me-3 rounded-pill ">Back</button>

                        {mock.module === 4 && isPreview ? <button onClick={() => handleSubmit()} className="btn btn-dark rounded-pill">Submit</button> :
                            <button onClick={() => handleNextButton()} className="btn btn-dark rounded-pill">Next</button>}
                    </div>
                </section>
            </div>

        </div>
    )
}

export default Mock