import React, { useState } from 'react';
import './Login.css';
import { useNavigate } from 'react-router-dom';
import { fetchTokenAPI } from '../../apis';
import { useDispatch } from 'react-redux';
import { fetchUserProfile, setIsAuthorized } from '../../redux/slice/userSlice';
import { message } from 'antd';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  const dispath = useDispatch()


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);

    const response = await fetchTokenAPI(username, password);
    if (response?.data.code === 1000) {
      dispath(setIsAuthorized(true))
      message.success('Login success')
      sessionStorage.setItem('accessToken', response?.data.result.token);
      setUsername(null);
      setPassword(null);
      navigate('/activities')
      dispath(fetchUserProfile())
     
    }
  }
  return (
    <div className="login-container d-flex justify-content-center align-items-start">
      <form className="login-form p-4" onSubmit={() => handleSubmit}>
        <h2 className="text-center mb-4">Login</h2>
        <div className="form-group">
          <label htmlFor="username">Username</label>
          <input
            type="text"
            className="form-control"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Enter username"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            className="form-control"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
            required
          />
        </div>
        <button type="submit" className="btn btn-dark btn-block mt-4" disabled={loading} onClick={handleSubmit}>
          {loading ? <div className="spinner-border" role="status"></div> : 'Login'}
        </button>


        <div className="mt-3 text-center">
          {/* <p>
            Don't have an account? &nbsn;
            <Link to="/register">Sign up here</Link>
          </p> */}
        </div>
      </form>

    </div>
  );
};

export default Login;